import React, {useContext} from "react"
import PropTypes from "prop-types"
import * as moment from "moment"
import ReactMarkdown from "react-markdown"

import { LangContext } from "../../../context/LangContext"

import icPdfRed from "../../../assets/img/icons/ic-pdf-red.png"

var idLocale = require('moment/locale/id'); 

export const PDF = ({ title, link }) => {
  const { lang, setLang } = useContext(LangContext)
  return (
    <>
      <a
        className="pdf-fold"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="PDF" src={icPdfRed} />
        <p dangerouslySetInnerHTML={{ __html: title }}></p>
        <button className="btn btn-link">
          {/* <i className="far fa-eye"></i> */}
          {lang == "EN" ? "View" : "Lihat"}
        </button>
      </a>
    </>
  )
}

PDF.propTypes = {
  year: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
}

PDF.defaultProps = {
  link: "https://gahp.net/wp-content/uploads/2017/09/sample.pdf",
}

export const PDF2 = ({ title, viewLink, fileId, publishedDate }) => {
  const { lang, setLang } = useContext(LangContext)
  return (
    <>
      <div className="pdf-fold-2">
        <img alt="PDF" src={icPdfRed} />
        <div className="content">
          <p className="font-weight-bold">
            {<ReactMarkdown source={title} escapeHtml={false} /> || "Title"}
          </p>
          <div className="file-size">
            <p className="text-muted">
              {/* {fileSize
                  ? `${fileSize < 1000 ? fileSize : fileSize / 1000} ${
                      fileSize < 1000 ? "KB" : "MB"
                    }`
                  : "1.1 MB"} */}
              {
                lang == "EN" ?  moment(publishedDate).locale('en').format("D MMMM YYYY • h:mm A") : moment(publishedDate).locale('id', idLocale).format("D MMMM YYYY • h:mm A")
              }
            </p>
          </div>
          <div className="links">
            <a
              className="btn btn-link fx-underline"
              href={`https://beryl-ks79.antikode.dev/app/download/index.php?id=${fileId}`}
              download
            >
            {lang == "EN" ? "Download" : "Unduh"}
            </a>
            <a
              href={
                viewLink ||
                "https://gahp.net/wp-content/uploads/2017/09/sample.pdf"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-link"
            >
            {lang == "EN" ? "View" : "Lihat"}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
