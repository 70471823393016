import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"

import { CardSlider } from "../../anti/card-slider/card-slider"
import { Section, Container } from "../../anti/grid/grid"
import { NavTabs, TabPane } from "../../anti/tabs/tabs"
import { PDF, PDF2 } from "../../anti/pdf"
import { Link } from "../../anti/utils/utils"

import { LangContext } from "../../../context/LangContext"


const groupBy = (array, key) => {
  if (array) {
    return array.reduce((result, currentValue) => {
      ;(result[currentValue["acfFile"][key]] =
        result[currentValue["acfFile"][key]] || []).push(currentValue)
      return result
    }, {})
  }
}

const yearComparison = (a, b) => {
  const reportA = a[0]
  const reportB = b[0]

  let comparison
  if (reportA < reportB) {
    comparison = 1
  } else if (reportA > reportB) {
    comparison = -1
  }

  return comparison
}

export const FilePDF = ({ data, slug }) => {
  let annualReports = []
  let financialReports = []

  data.map(item => {
    if (
      item.file_categories.nodes &&
      item.file_categories.nodes[0] &&
      item.file_categories.nodes[0].slug === "annual-reports"
    ) {
      annualReports.push(item)
    }
  })

  data.map(item => {
    if (
      item.file_categories.nodes &&
      item.file_categories.nodes[0] &&
      item.file_categories.nodes[0].slug === "financial-reports"
    ) {
      financialReports.push(item)
    }
  })

  // group reports by year
  const groupAnnualByYear =
    annualReports.length > 0 && groupBy(annualReports, "year")
  const groupFinancialByYear =
    financialReports.length > 0 && groupBy(financialReports, "year")

  // split grouped reports into arrays
  const annualArr =
    (groupAnnualByYear &&
      Object.keys(groupAnnualByYear).map(key => [
        key,
        groupAnnualByYear[key],
      ])) ||
    []
  const financialArr =
    (groupFinancialByYear &&
      Object.keys(groupFinancialByYear).map(key => [
        key,
        groupFinancialByYear[key],
      ])) ||
    []

  return (
    <Section>
      <Container>
        {slug === "annual-reports" &&
          annualArr.length > 0 &&
          annualArr.sort(yearComparison).map((item, i) => {
            return (
              <div className="row pb-5" key={`${item[0]}-${i}`}>
                <div className="col-12 pb-3">
                  <div className="header-pdf">
                    <h2>{item[0]}</h2>
                  </div>
                </div>
                {item[1].map(file => {
                  return (
                    <div className="col-md-6 cl-12 mb-3" key={file.id}>
                      <PDF2
                        key={file.id}
                        title={file.title}
                        viewLink={file.acfFile.file.mediaItemUrl}
                        fileSize={file.acfFile.file.filesize}
                        fileId={file.acfFile.file.databaseId}
                        publishedDate={
                          file.date
                        }
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
        {slug === "financial-reports" &&
          financialArr.length > 0 &&
          financialArr.sort(yearComparison).map((item, i) => {
            return (
              <div className="row pb-5" key={`${item[0]}-${i}`}>
                <div className="col-12 pb-3">
                  <div className="header-pdf">
                    <h2>{item[0]}</h2>
                  </div>
                </div>
                {item[1].map(file => {
                  return (
                    <div className="col-md-6 col-12" key={file.id}>
                      <PDF2
                        title={file.title}
                        viewLink={file.acfFile.file.mediaItemUrl}
                        fileSize={file.acfFile.file.filesize}
                        fileId={file.acfFile.file.databaseId}
                        publishedDate={
                          file.date
                        }
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}

        {/* <TabPane id="financial-report" className="pt-3">
          {}
        </TabPane> */}
      </Container>
    </Section>
  )
}

export const AnggaranReportSection = ({ title, data, id }) => {
  let anggaranReport =
    data &&
    data.reduce((result, currentValue) => {
      if (
        currentValue.file_categories.nodes[0] &&
        currentValue.file_categories.nodes[0].slug === (id || "anggaran")
      ) {
        result.push(currentValue)
      }
      return result
    }, [])

  // group reports by year
  const groupReportByYear = anggaranReport.length > 0 && groupBy(anggaranReport, "year")
  // split grouped reports into arrays
  const reportArr =
    (groupReportByYear &&
      Object.keys(groupReportByYear).map(key => [
        key,
        groupReportByYear[key],
      ])) ||
    []

  let navTab = []
  reportArr.map(item =>
    navTab.push({ name: item[0], target: `${id}${item[0]}` })
  )
  return (
    <Section className="pt-4 pb-4 bg-light">
      <Container>
        <h2>{title}</h2>
        {navTab.length > 0 && (
          <NavTabs
            wrapperClassName="report-wrapper"
            navItemClassName="bg-light"
            className="report-nav-tab"
            identifier={id}
            data={navTab}
          />
        )}
        {navTab.length > 0 &&
          reportArr.reverse().map((item, i) => {
            return (
              <TabPane
                id={`${id}${item[0]}`}
                identifier={id}
                key={`${id}${item[0]}${i}`}
                className={`${i === 0 ? "active" : ""}`}
              >
                <div className="row">
                  {item[1].map((file, index) => {
                    return (
                      <div
                        className="col-md-6 col-12 mb-3"
                        key={`${id}${item[0]}${i}${index}`}
                      >
                        <PDF2
                          title={file.title}
                          viewLink={
                            file.acfFile.file && file.acfFile.file.mediaItemUrl
                          }
                          fileSize={
                            (file.acfFile.file && file.acfFile.file.filesize) ||
                            1000
                          }
                          fileId={
                            file.acfFile.file && file.acfFile.file.databaseId
                          }
                          publishedDate={
                            file.date
                          }
                        />
                      </div>
                    )
                  })}
                </div>
              </TabPane>
            )
          })}
      </Container>
    </Section>
  )
}

export const PdfSection = ({ title, data, id }) => {
  let rupsReport =
    data &&
    data.reduce((result, currentValue) => {
      if (
        currentValue.file_categories.nodes[0] &&
        currentValue.file_categories.nodes[0].slug === (id || "rups")
      ) {
        result.push(currentValue)
      }
      return result
    }, [])

  // group reports by year
  const groupReportByYear = rupsReport.length > 0 && groupBy(rupsReport, "year")
  // split grouped reports into arrays
  const reportArr =
    (groupReportByYear &&
      Object.keys(groupReportByYear).map(key => [
        key,
        groupReportByYear[key],
      ])) ||
    []

  let navTab = []
  reportArr.map(item =>
    navTab.push({ name: item[0], target: `${id}${item[0]}` })
  )
  return (
    <Section className="pt-4 pb-4 bg-light">
      <Container>
        <h2>{title}</h2>
        {navTab.length > 0 && (
          <NavTabs
            wrapperClassName="report-wrapper"
            navItemClassName="bg-light"
            className="report-nav-tab"
            identifier={id}
            data={navTab}
          />
        )}
        {navTab.length > 0 &&
          reportArr.reverse().map((item, i) => {
            return (
              <TabPane
                id={`${id}${item[0]}`}
                identifier={id}
                key={`${id}${item[0]}${i}`}
                className={`${i === 0 ? "active" : ""}`}
              >
                <div className="row">
                  {item[1].map((file, index) => {
                    return (
                      <div
                        className="col-md-6 col-12 mb-3"
                        key={`${id}${item[0]}${i}${index}`}
                      >
                        <PDF2
                          title={file.title}
                          viewLink={
                            file.acfFile.file && file.acfFile.file.mediaItemUrl
                          }
                          fileSize={
                            (file.acfFile.file && file.acfFile.file.filesize) ||
                            1000
                          }
                          fileId={
                            file.acfFile.file && file.acfFile.file.databaseId
                          }
                          publishedDate={
                            file.date
                          }
                        />
                      </div>
                    )
                  })}
                </div>
              </TabPane>
            )
          })}
      </Container>
    </Section>
  )
}

export const Reports = ({ title, data, attributes }) => {
  const { lang, setLang } = useContext(LangContext)
  function compare_year(a, b) {
    if (a.acfFile.year > b.acfFile.year) {
      return -1
    } else if (a.acfFile.year < b.acfFile.year) {
      return 1
    } else {
      return 0
    }
  }
  return (
    <Section className="bg-light">
      <Container className="text-md-center">
        <h2 className={`mb-5`}>{title}</h2>
      </Container>
      <Container>
        <CardSlider
          showInitial={7}
          showSmDown={2.5}
          visibleInitial={false}
          visibleMdDown={true}
          arrowsInitial={false}
          arrowsLgDown={false}
        >
          {data &&
            data.sort(compare_year) &&
            data.sort(compare_year).map((item, i) => {
              return (
                <PDF
                  key={item.id}
                  title={item.acfFile.year}
                  link={item.acfFile.file && item.acfFile.file.mediaItemUrl}
                />
              )
            })}
        </CardSlider>
      </Container>
      <Container className="text-md-center">
        <div className={`mt-5`}>
          <Link
            className="fx-underline text-uppercase"
            to={
              attributes === "annual-report"
                ? "/investor/annual-reports"
                : attributes
                ? "/sustainability/reports"
                : "/"
            }
          >
            {attributes === "annual-report" && `${lang == "EN" ? "All Annual Reports" : "Semua Laporan Tahunan."}`}
            {attributes === "sustainabilty-reports" &&
              `${lang == "EN" ? "All Sustainability Reports" : "Semua Laporan Keberlanjutan."}`}
          </Link>
        </div>
      </Container>
    </Section>
  )
}

Reports.propTypes = {
  className: PropTypes.string, // Set feature optional classes
}

Reports.defaultProps = {
  className: "",
}

export const PdfSection2 = ({ data, id }) => {
  let pressRelease = []

  data &&
    data.map(item => {
      if (
        item.file_categories.nodes[0] &&
        item.file_categories.nodes[0].slug === id
      ) {
        pressRelease.push(item)
      }
    })

  // group reports by year
  const groupPressReleaseByYear =
    pressRelease.length > 0 && groupBy(pressRelease, "year")

  // split grouped reports into arrays
  const pressReleaseArr =
    (groupPressReleaseByYear &&
      Object.keys(groupPressReleaseByYear).map(key => [
        key,
        groupPressReleaseByYear[key],
      ])) ||
    []

  return (
    <Section className="bg-light">
      <Container>
        {pressReleaseArr.length > 0 &&
          pressReleaseArr.sort(yearComparison).map((item, i) => {
            return (
              <div className="row pb-5" key={`${item[0]}-${i}`}>
                <div className="col-12 pb-3">
                  <div className="header-pdf">
                    <h2>{item[0]}</h2>
                  </div>
                </div>
                {item[1].map(file => {
                  return (
                    <div className="col-md-6 cl-12" key={file.id}>
                      <PDF2
                        key={file.id}
                        title={file.title}
                        viewLink={`${file.acfFile.file.mediaItemUrl}`}
                        fileSize={file.acfFile.file.filesize}
                        fileId={file.acfFile.file.databaseId}
                        publishedDate={
                          file.date
                        }
                      />
                    </div>
                  )
                })}
              </div>
            )
          })}
      </Container>
    </Section>
  )
}

export const BoardOfDirectors = ({ className, data, slug }) => {
  const reports = [
    {
      title: "Charter of Directors",
      link:
        "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    },
    {
      title: "Charter of Commisary",
      link:
        "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
    },
  ]
  return (
    <Section className="bg-light">
      <Container>
        <div className="mb-4">
          <div className="row">
            <div className="col-12">
              <div className="header-pdf">
                <h2>Find out more</h2>
              </div>
            </div>
            {data &&
              data.map((file, i) => {
                if (file.file_categories.nodes[0].slug == "boc-bod-report") {
                  return (
                    <div className="col-md-6 col-12 mb-3">
                      <PDF2
                        title={file.title}
                        fileId={
                          file.acfFile.file && file.acfFile.file.databaseId
                        }
                        viewLink={
                          file.acfFile.file && file.acfFile.file.mediaItemUrl
                        }
                        fileSize={
                          file.acfFile.file && file.acfFile.file.filesize
                        }
                        publishedDate={
                          file.date
                        }
                      />
                    </div>
                  )
                }
              })}
          </div>
        </div>
      </Container>
    </Section>
  )
}

BoardOfDirectors.propTypes = {
  className: PropTypes.string, // Set feature optional classes
}

BoardOfDirectors.defaultProps = {
  className: "",
}

export const Piagam = ({ data, id, title }) => {
  let piagamReport =
    data &&
    data.reduce((result, currentValue) => {
      if (
        currentValue.file_categories.nodes[0] &&
        currentValue.file_categories.nodes[0].slug === (id || "piagam")
      ) {
        result.push(currentValue)
      }
      return result
    }, [])

  return (
    <Section className="pb-3 bg-light">
      <Container>
        <div className="header-pdf">
          {title && <h2 className="mb-3">{title}</h2>}
        </div>
        <div className="row">
          {piagamReport.map((file, index) => {
            return (
              <div className="col-md-6 col-12 mb-3" key={`${file.id}`}>
                <PDF2
                  title={file.title}
                  viewLink={file.acfFile.file && file.acfFile.file.mediaItemUrl}
                  fileSize={file.acfFile.file && file.acfFile.file.filesize}
                  fileId={file.acfFile.file && file.acfFile.file.databaseId}
                  publishedDate={file.date}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}

Piagam.propTypes = {
  className: PropTypes.string, // Set feature optional classes
}

Piagam.defaultProps = {
  className: "",
}
