import React from "react"
import { graphql, Link } from "gatsby"
import { Link as AntiLink } from "../../anti/utils/utils"
import ReactMarkdown from "react-markdown"

import { Section, Container } from "../../anti/grid/grid"
import { Feature } from "../../anti/feature/feature"

export const acfIconListBlock = graphql`
  fragment AcfIconListBlock on WordPress_AcfIconlistBlock {
    name
    attributes {
      className
    }
    acfBlockIconList {
      bgColor
      title
      text
      list {
        icon {
          mediaItemUrl
        }
        title
        description
      }
    }
  }
`

export const AcfIconList = ({ data }) => {
  const acfBlockIconList = data.acfBlockIconList
  return (
    <Section
      className={`${acfBlockIconList.bgColor} ${data.attributes.className}`}
    >
      <Container>
        <h2 className="h2">
          <ReactMarkdown source={acfBlockIconList.title} escapeHtml={false} />
        </h2>
        <p>
          <ReactMarkdown source={acfBlockIconList.text} escapeHtml={false} />
        </p>
        <div className="row row-3 mt-5">
          {acfBlockIconList.list.map((item, i) => {
            return (
              <div className="col-md-4">
                <Feature
                  img={item.icon && item.icon.mediaItemUrl}
                  title={item.title}
                  text={item.description}
                  key={i}
                />
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}

export const AcfIconListLink = ({ data }) => {
  const acfBlockIconList = data.acfBlockIconList
  return (
    <Section
      className={`${acfBlockIconList.bgColor} ${data.attributes.className}`}
    >
      <Container>
        <h2 className="h2">
          <ReactMarkdown source={acfBlockIconList.title} escapeHtml={false} />
        </h2>
        <p>
          <ReactMarkdown source={acfBlockIconList.text} escapeHtml={false} />
        </p>
        <div className="row row-3 mt-5">
          {acfBlockIconList.list.map((item, i) => {
            return (
              <div className="col-md-6 mb-4" key={`${item.title}${i}`}>
                <div className="feature">
                  <AntiLink
                    className="feature-body flex-row align-items-center"
                    to={item && item.link && item.link.url}
                    target=""
                  >
                    <img
                      alt="quick access icon"
                      className="feature-img mb-0"
                      src={item.icon && item.icon.mediaItemUrl}
                    />
                    <h4 className="feature-title mb-0">
                      <ReactMarkdown source={item.title} escapeHtml={false} />
                    </h4>
                  </AntiLink>
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}
